<template>
  <el-dialog
    :title="`${info ? '编辑' : '新增'}小程序群发`"
    :visible.sync="show"
    :fullscreen="true"
    append-to-body
    center
    :before-close="handleClose"
  >
    <el-row :gutter="20">
      <el-form label-position="left" label-width="120px" class="form-container">
        <!-- 表单顶部 -->
        <div class="form-top">
          <el-form-item label="公众号：">
            <el-select
              style="width: 100%"
              v-model="selectChannel"
              value-key="id"
              filterable
              placeholder="公众号"
              disabled
            >
              <el-option
                v-for="item in account"
                :key="item.id"
                :value="item"
                :label="item.channelName"
              >
                <span style="float: left">{{ item.channelName }}</span>
                <span style="float: right; margin-left: 15px; font-size: 13px"
                  >{{ item.platformStr }} - {{ item.uname }}</span
                >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input
              v-model="form.remark"
              placeholder="给这条消息起个名字便于查询"
            ></el-input>
          </el-form-item>
        </div>
        <!-- 表单中部 -->
        <div class="form-body">
          <div class="form-body-left">
            <!-- 新增互动类型 -->
            <el-form-item label="定时日期">
              <el-date-picker
                v-model="form.tick"
                align="right"
                type="date"
                value-format="yyyy-MM-dd"
                style="width: 180px"
                placeholder="选择日期"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
              <!-- <span style="margin: 0 10px">定时时间:</span> -->
            </el-form-item>
            <el-form-item label="定时时间">
              <el-time-picker
                v-model="form.tickTime"
                style="width: 180px"
                value-format="HH:mm:ss"
                :picker-options="{
                  selectableRange: '00:00:01 - 23:59:59',
                }"
                placeholder="选择时间"
              >
              </el-time-picker>
            </el-form-item>
            <el-form-item label="间隔天数">
              <el-input-number
                v-model="form.intervalTime"
                :step-strictly="true"
                :step="1"
                style="width: 180px"
                controls-position="right"
                :min="0"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="接收用户" style="margin-bottom: 14px">
              <el-radio-group
                v-model="form.sendAll"
                @change="estimateNum = null"
                size="medium"
              >
                <el-radio-button :label="1">全部用户</el-radio-button>
                <el-radio-button :label="0">标签用户</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="form-body-right">
            <el-form label-width="120px">
              <el-form label-width="85px">
                <!-- 基础属性新增 -->
                <!-- 基础属性新增结束 -->

                <el-form-item label="充值情况">
                  <el-radio-group v-model="form.rechargeType" size="medium">
                    <el-radio-button :label="0">不限</el-radio-button>
                    <el-radio-button :label="1">未充值</el-radio-button>
                    <el-radio-button :label="2">已充值</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <div class="inner-form" v-if="form.rechargeType === 2">
                  <el-form-item label="充值间隔" v-if="form.rechargeType === 2">
                    <el-input-number
                      style="width: 165px"
                      v-model="form.rechargeTimeSpaceStart"
                      :step-strictly="true"
                      :step="1"
                      placeholder="开始天数"
                      controls-position="right"
                      :min="0"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      style="width: 165px"
                      v-model="form.rechargeTimeSpaceEnd"
                      :step-strictly="true"
                      :step="1"
                      placeholder="结束天数"
                      controls-position="right"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>
                  <!-- 阳光书城 掌中云天机剩余书币 -->
                  <el-form-item
                    label="剩余书币"
                    v-if="
                      form.rechargeType === 2 &&
                      (selectChannel.platform === 3 ||
                        selectChannel.platform === 0)
                    "
                  >
                    <el-input-number
                      style="width: 165px"
                      v-model="form.minKanDian"
                      placeholder="最小剩余书币"
                      :min="0"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      style="width: 165px"
                      v-model="form.maxKanDian"
                      placeholder="最大剩余书币"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item
                    label="书币消耗率"
                    v-if="
                      form.rechargeType === 2 &&
                      [0, 3].includes(selectChannel.platform)
                    "
                  >
                    <el-input-number
                      style="width: 165px"
                      v-model="form.minKanDianRate"
                      placeholder="最小书币消耗率"
                      :precision="2"
                      :min="0"
                      :step="1"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      style="width: 165px"
                      v-model="form.maxKanDianRate"
                      placeholder="最大书币消耗率"
                      :precision="2"
                      :min="0"
                      :step="1"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item label="累计充值" v-if="form.rechargeType === 2">
                    <el-input-number
                      style="width: 165px"
                      v-model="form.minTotalRecharge"
                      placeholder="最小充值"
                      :precision="2"
                      :step="1"
                      :min="0"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      style="width: 165px"
                      placeholder="最大充值"
                      v-model="form.maxTotalRecharge"
                      :precision="2"
                      :step="1"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item label="最后充值" v-if="form.rechargeType === 2">
                    <el-input-number
                      style="width: 165px"
                      v-model="form.minLastRechargeAmt"
                      placeholder="最小充值"
                      :precision="2"
                      :step="1"
                      :min="0"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      style="width: 165px"
                      placeholder="最大充值"
                      v-model="form.maxLastRechargeAmt"
                      :precision="2"
                      :step="1"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item label="充值次数" v-if="form.rechargeType === 2">
                    <el-input-number
                      style="width: 165px"
                      v-model="form.minRechargeCount"
                      placeholder="最小次数"
                      :step="1"
                      :min="0"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      style="width: 165px"
                      v-model="form.maxRechargeCount"
                      placeholder="最大次数"
                      :step="1"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>

                  <!-- 统计属性 -->
                  <!-- 最近7日价值 -->
                  <el-form-item
                    label="近7日充值"
                    v-if="form.rechargeType === 2"
                  >
                    <el-input-number
                      style="width: 165px"
                      v-model="form.minSevenRecharge"
                      placeholder="最小充值"
                      :min="0"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      style="width: 165px"
                      v-model="form.maxSevenRecharge"
                      placeholder="最大充值"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>

                  <div class="vip-consume">
                    <!-- 是否vip -->
                    <el-form-item
                      style="width: 220px"
                      label="VIP"
                      label-width="45px"
                      v-if="form.rechargeType === 2"
                    >
                      <el-radio-group v-model="form.isVip">
                        <el-radio :label="2">不限</el-radio>
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <!-- 连续三周均有充值 -->

                    <div class="consume-box" style="width: 60%">
                      <el-tooltip
                        style="margin-left: 20px"
                        class="item"
                        effect="dark"
                        content="用户最近三周内每周均有充值"
                        placement="left"
                      >
                        <i class="el-icon-question"></i>
                      </el-tooltip>
                      <el-form-item
                        label-width="80px"
                        label="连续付费"
                        v-if="form.rechargeType === 2"
                      >
                        <el-radio-group v-model="form.continuousRecharge">
                          <el-radio :label="0">不限</el-radio>
                          <el-radio :label="1">是</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                  </div>
                  <!-- rfm -->
                  <div class="rfm-box">
                    <el-popover placement="right" width="500" trigger="hover">
                      <el-table :data="gridData" height="300">
                        <el-table-column
                          width="120"
                          property="label"
                          label="客户标签"
                        ></el-table-column>
                        <el-table-column
                          width="36"
                          property="R"
                          label="R"
                        ></el-table-column>
                        <el-table-column
                          width="36"
                          property="F"
                          label="F"
                        ></el-table-column>
                        <el-table-column
                          width="36"
                          property="M"
                          label="M"
                        ></el-table-column>
                        <el-table-column
                          property="scene"
                          label="场景"
                        ></el-table-column>
                        <el-table-column
                          width="50"
                          property="level"
                          label="等级"
                        ></el-table-column>
                      </el-table>
                      <i
                        slot="reference"
                        style="margin-left: 20px"
                        class="el-icon-question"
                      ></i>
                    </el-popover>
                    <el-form-item
                      label="RFM"
                      label-width="60px"
                      v-if="form.rechargeType === 2"
                    >
                      <el-select
                        v-model="form.rfm"
                        style="width: 350px"
                        placeholder="请选择rfm"
                        multiple
                        clearable
                      >
                        <el-option label="A类" :value="1"></el-option>
                        <el-option label="B类" :value="2"></el-option>
                        <el-option label="C类" :value="3"></el-option>
                      </el-select>
                      <!-- 解释表格 -->
                    </el-form-item>
                  </div>
                  <!-- 剩余优惠券 -->
                  <el-form-item
                    label="优惠敏感度"
                    class="add-element"
                    v-if="form.rechargeType === 2"
                  >
                    <el-select
                      style="width: 350px"
                      v-model="form.couponLevel"
                      placeholder="请选择优惠券级别"
                    >
                      <el-option label="不限" :value="0"></el-option>
                      <el-option label="1级" :value="1"></el-option>
                      <el-option label="2级" :value="2"></el-option>
                      <el-option label="3级" :value="3"></el-option>
                      <el-option label="4级" :value="4"></el-option>
                      <el-option label="5级" :value="5"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="书籍" v-if="form.rechargeType === 2">
                    <el-select
                      v-model="form.bookId"
                      filterable
                      remote
                      style="width: 350px"
                      reserve-keyword
                      clearable
                      placeholder="请输入书籍名称"
                      :remote-method="getBooks"
                      :loading="loading"
                    >
                      <el-option
                        v-if="defaultBook"
                        :label="defaultBook.bookName"
                        :value="defaultBook.id"
                      ></el-option>
                      <el-option
                        v-for="item in bookList"
                        :key="item.id"
                        :label="item.bookName"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </el-form>
              <template>
                <el-form-item label-width="90px" label="性别">
                  <el-radio-group v-model="form.sex">
                    <el-radio :label="0">不限</el-radio>
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="2">女</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="所在地区" label-width="85px">
                  <el-select
                    size="small"
                    style="width: 100px"
                    v-model="form.province"
                    filterable
                    placeholder="省份"
                    clearable
                  >
                    <el-option
                      v-for="item in area"
                      :key="item.cid"
                      :label="item.name"
                      :value="item.name"
                    ></el-option>
                  </el-select>
                  <el-select
                    size="small"
                    style="width: 100px"
                    v-model="form.city"
                    filterable
                    placeholder="城市"
                    clearable
                  >
                    <el-option
                      v-for="item in areaCity"
                      :key="item.cid"
                      :label="item.name"
                      :value="item.name"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="关注时间" label-width="85px">
                  <el-date-picker
                    v-model="form.startDate"
                    align="right"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 180px"
                    placeholder="开始时间"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                  <el-date-picker
                    v-model="form.endDate"
                    align="right"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 180px; margin-left: 10px"
                    placeholder="结束时间"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="签到次数" label-width="85px">
                  <el-input-number
                    style="width: 165px"
                    v-model="form.minSignInCount"
                    placeholder="最小签到次数"
                    :min="0"
                    :step="1"
                  ></el-input-number>
                  <span style="margin: 0 10px"></span>

                  <el-input-number
                    style="width: 165px"
                    v-model="form.maxSignInCount"
                    placeholder="最大签到次数"
                    :min="0"
                    :step="1"
                  ></el-input-number>
                </el-form-item>
              </template>
            </el-form>
            <div class="form-body-right-mask" v-if="form.sendAll !== 0">
              <p>仅标签用户可操作</p>
            </div>
          </div>
        </div>
      </el-form>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <div class="sub-box">
        <div class="button-box">
          <el-button @click="handleClose">取 消</el-button>
          <el-button
            style="margin-left: 10px"
            type="primary"
            @click="handleSubmit"
            :loading="submitLoading"
            >确定</el-button
          >
        </div>
        <div style="display: flex; justify-content: center">
          <span>
            <el-button type="text" @click="getNum"
              >点击查看预计可送达人数</el-button
            >
            <span
              @click="showDetail = true"
              v-if="typeof estimateNum === 'number'"
              :class="estimateNum ? 'person-count' : ''"
              style="margin-left: 20px; font-size: 14px"
              >预计可送达：{{ estimateNum }}人</span
            >
          </span>
        </div>
      </div>
    </span>
    <!-- 发送人详情 -->
    <SendDetailTable
      :params="getNumParams"
      @closeDetail="closeDetail"
      :show="showDetail"
      type="tag"
    />
  </el-dialog>
</template>

<script>
import area from "@/assets/js/area.js";
import { getBookList } from "@/api/account";
import {
  addAppletSend,
  changeAppletSend,
  detailAppletSend,
} from "@/api/service";
import { getSendTagNum } from "@/api/service";
import { getClassifyList } from "@/api/classify";
import SendDetailTable from "../../service/components/sendDetailTable.vue";
import { messagePickerOptions } from "@/assets/js/options";

const defaultForm = {
  sendAll: 1,
  continuousRecharge: 0,
  sex: 0,
  rechargeType: 2,
  isVip: 2,
  rfm: 0,
  couponLevel: 0,
  bookId: null,
  startDate: null,
  endDate: null,
  remark: "",
  novelName: "",
  url: "",
  fixedNum: null,
  tagType: 1,
};
export default {
  name: "edit",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    account: {
      type: Array,
      default: () => [],
    },
    info: Object,
    accountId: Number,
  },
  data() {
    return {
      testId: null,
      str: "",
      estimateNum: null,
      form: {},
      classifyList: [],
      showDescDialog: false,
      pickerOptions: messagePickerOptions,
      bookList: [],
      loading: false,
      formLoading: false,
      defaultBook: null,
      submitLoading: false,
      selectChannel: null, // 选中公众号
      testUser: [],
      remoteLoading: false,
      // 新增客户信息相关
      area,
      areaCity: [],
      gridData: [
        {
          label: "重要价值客户",
          R: "高",
          F: "高",
          M: "高",
          scene: "最近充值-高频次-高金额",
          level: "A类",
        },
        {
          label: "重要深耕客户",
          R: "高",
          F: "低",
          M: "高",
          scene: "最近充值-低频次-高金额",
          level: "A类",
        },
        {
          label: "重要唤回客户",
          R: "低",
          F: "高",
          M: "高",
          scene: "最近未充-高频次-高金额",
          level: "B类",
        },
        {
          label: "重要挽留客户",
          R: "低",
          F: "低",
          M: "高",
          scene: "最近未充-低频次-高金额",
          level: "B类",
        },
        {
          label: "潜力客户",
          R: "高",
          F: "高",
          M: "低",
          scene: "最近充值-高频次-低金额",
          level: "B类",
        },
        {
          label: "一般发展客户",
          R: "高",
          F: "低",
          M: "低",
          scene: "最近充值-低频次-低金额",
          level: "B类",
        },
        {
          label: "一般维持客户",
          R: "低",
          F: "高",
          M: "低",
          scene: "最近未充-高频次-低金额",
          level: "B类",
        },
        {
          label: "流失客户",
          R: "低",
          F: "低",
          M: "低",
          scene: "最近未充-低频次-低金额",
          level: "C类",
        },
      ],
      // 获取人数参数
      showDetail: false,
      getNumParams: {},
    };
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.estimateNum = null;
        if (this.info) {
          this.initData();
        } else {
          this.form = { ...defaultForm };
          this.wechartFormData = {};
          const defaultAccount = this.account.find(
            (item) => item.id === this.accountId
          );
          this.selectChannel = {
            id: this.accountId,
            channelName: defaultAccount ? defaultAccount.channelName : "",
            platform: defaultAccount ? defaultAccount.platform : null,
          };
        }
      }
    },
    "form.province": {
      handler(newValue, oldValue) {
        this.area.forEach((item) => {
          if (item.name === this.form.province) {
            this.areaCity = item.children;
          }
        });
        if (newValue === "") {
          this.areaCity = [];
        }
        if (oldValue !== undefined) {
          this.$set(this.form, "city", null);
        }
      },
    },
  },
  mounted() {
    getBookList({ page: 1, pageSize: 50 }).then((res) => {
      this.bookList = res.list;
    });
    getClassifyList({ type: 1, status: 1 }).then((res) => {
      this.classifyList = res;
    });
  },
  computed: {
    getPlaceholder() {
      return function (data) {
        if (data.title) {
          return data.title + "：" + data.value;
        } else {
          return data.value;
        }
      };
    },
  },
  methods: {
    // 关闭详情
    closeDetail() {
      this.showDetail = false;
    },
    handleParams() {
      let params = {
        accountChannelId: this.selectChannel.id,
        sendAll: this.form.sendAll,
        hourSpaceStart: this.form.hourSpaceStart,
        hourSpaceEnd: this.form.hourSpaceEnd,
        tagType: this.form.tagType,
        minSendNum: this.form.minSendNum,
        maxSendNum: this.form.maxSendNum,
      };
      if (this.form.sendAll !== 1) {
        params.rechargeType = this.form.rechargeType;
        params.bookId = this.form.bookId;
        params.sex = this.form.sex;
        params.startDate = this.form.startDate;
        params.endDate = this.form.endDate;
        params.rechargeTimeSpaceStart = this.form.rechargeTimeSpaceStart;
        params.rechargeTimeSpaceEnd = this.form.rechargeTimeSpaceEnd;
        params.minTotalRecharge = this.form.minTotalRecharge;
        params.maxTotalRecharge = this.form.maxTotalRecharge;
        // 最大最小最后充值
        params.minLastRechargeAmt = this.form.minLastRechargeAmt;
        params.maxLastRechargeAmt = this.form.maxLastRechargeAmt;
        params.minRechargeCount = this.form.minRechargeCount;
        params.maxRechargeCount = this.form.maxRechargeCount;
        // 新增城市选择
        params.province = this.form.province;
        params.city = this.form.city;
        params.continuousRecharge = this.form.continuousRecharge;
        params.maxSevenRecharge = this.form.maxSevenRecharge;
        params.minSevenRecharge = this.form.minSevenRecharge;
        params.minSendNum = this.form.minSendNum;
        params.maxSendNum = this.form.maxSendNum;
        params.isVip = this.form.isVip;
        params.rfm =
          Array.from(this.form.rfm) && this.form.rfm.length === 0
            ? "0"
            : !this.form.rfm
            ? "0"
            : this.form.rfm.join(",");
        params.couponLevel = this.form.couponLevel;
      }
      // 新添加阳光金币展示
      if (
        (this.selectChannel.platform === 3 ||
          this.selectChannel.platform === 0) &&
        this.form.rechargeType === 2
      ) {
        params.minKanDian = this.form.minKanDian;
        params.maxKanDian = this.form.maxKanDian;
      }
      return params;
    },
    getNum() {
      if (this.selectChannel) {
        let params = this.handleParams();
        this.getNumParams = params;
        getSendTagNum(params).then((res) => {
          this.estimateNum = typeof res === "number" ? res : 0;
        });
      } else {
        this.$message.error("请选择公众号");
      }
    },
    initData() {
      this.formLoading = true;
      detailAppletSend(this.info.id).then((res) => {
        const {
          content,
          accountChannelId,
          templateName,
          channelName,
          data,
          ...other
        } = res;
        const defaultAccount = this.account.find(
          (item) => item.id === this.accountId
        );
        this.selectChannel = {
          id: defaultAccount.id,
          channelName: defaultAccount.channelName,
          platform: defaultAccount ? defaultAccount.platform : null,
        };
        this.form = {
          ...this.info,
          ...other,
        };
        // this.form = other;
        this.form.rfm =
          other.rfm === "0" || !other.rfm || other.rfm === "[]"
            ? []
            : other.rfm.split(",").map((item) => Number(item));
        this.$set(this.form, "remark", this.info.remark);
      });
    },
    handleSubmit() {
      if (!this.form.remark) {
        this.$message.error("请输入备注");
        return false;
      }
      if (!this.form.tick) {
        this.$message.error("请选择定时日期");
        return false;
      }
      if (!this.form.intervalTime) {
        this.$message.error("请选择间隔天数");
        return false;
      }
      // if (!this.form.nextStartDate) {
      //   this.$message.error("请选择下次发送日期");
      //   return false;
      // }
      if (!this.form.tickTime) {
        this.$message.error("请选择定时时间");
        return false;
      }
      let params = this.handleParams();
      // console.log(this.form.rfm, "this.form.rfm");
      // params.rfm =
      //   Array.from(this.form.rfm) && this.form.rfm.length === 0
      //     ? "0"
      //     : !this.form.rfm
      //     ? "0"
      //     : this.form.rfm.join(",");
      this.submitLoading = true;
      if (this.info) {
        changeAppletSend(this.info.id, {
          remark: this.form.remark,
          tick: this.form.tick,
          intervalTime: this.form.intervalTime,
          // nextStartDate: this.form.nextStartDate,
          tickTime: this.form.tickTime,
          paramJson: JSON.stringify(params),
        })
          .then((res) => {
            this.$message.success("修改成功");
            this.$emit("close");
            this.$emit("refresh");
          })
          .finally(() => {
            this.submitLoading = false;
          });
      } else {
        addAppletSend({
          remark: this.form.remark,
          tick: this.form.tick,
          intervalTime: this.form.intervalTime,
          // nextStartDate: this.form.nextStartDate,
          tickTime: this.form.tickTime,
          accountChannelId: this.selectChannel.id,
          paramJson: JSON.stringify(params),
        })
          .then((res) => {
            this.$message.success("新增成功");
            this.$emit("close");
            this.$emit("refresh");
          })
          .finally(() => {
            this.submitLoading = false;
          });
      }
    },
    getBooks(query) {
      this.defaultBook = null;
      getBookList({ page: 1, pageSize: 50, bookName: query }).then((res) => {
        this.bookList = res.list;
      });
    },
    handleClose() {
      this.$emit("close");
    },
  },
  components: {
    SendDetailTable,
  },
};
</script>

<style lang='scss' scoped>
.form-container {
  width: 1300px;
  margin: auto;
}
.form-container-left {
  width: 600px;
  margin-left: auto;
}
.form-container-right {
  width: 600px;
  margin-right: auto;
}
/deep/ .el-button {
  margin-right: 0px !important;
  margin-left: 0px;
}
/deep/ .el-drawer__body {
  overflow-y: auto;
}
/deep/ .el-drawer {
  padding: 20px;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
// /deep/ .el-input-number__decrease{
//   top:1px;
// }
a {
  -webkit-tap-highlight-color: transparent;
}

.example {
  width: 375px;
  background-color: #f1f1f1;
  padding: 10px;
}

.card {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
}

.mesg-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-weight: bold;
  line-height: 20px;
}

.detail {
  display: flex;
  align-items: center;
}

.detail p {
  flex: 1;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 15px;
}

.detail img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: #f5f7fa;
  color: #909399;
}
.message-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-mask {
  border: 1px solid #c0c4cc;
  border-radius: 4px;
  min-height: 259px;
}
.dialog-footer {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
/* .text-mask > span {
  padding-left: 10px;
  font-size: 12px;
} */

/**
**
**改版客服消息排版相关css
**
**/
// 新增属性
.inner-form {
  width: 480px;
  border: 1px solid #f6f6f6;
  border-radius: 20px;
  padding: 10px;
  background-color: #f6f6f6;
  margin-left: -10px;
}
.form-container /deep/ .el-form-item__label {
  font-size: 13px;
}
.form-container /deep/ .el-radio-button--medium .el-radio-button__inner {
  font-size: 13px;
}
.form-container .el-radio__label {
  font-size: 13px;
}
/deep/ .el-dialog__body {
  padding-bottom: 0 !important;
  padding-top: 10px !important;
}
.el-form-item--small.el-form-item {
  margin-bottom: 10px;
}
.form-top {
  background-color: #fbfbfb;
  padding: 10px 20px 0 20px;
}
/deep/ .necessary > .el-form-item__label {
  position: relative;
}
/deep/ .necessary > .el-form-item__label::before {
  position: absolute;
  top: 0;
  left: -8px;
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
.form-body {
  background-color: #fbfbfb;
  margin-top: 10px;
  padding: 0 20px;
  padding-bottom: 0;
  display: flex;
  .message-box {
    justify-content: flex-start;
  }
  .form-body-left {
    border-right: 1px dashed #d9d9d9;
  }
  .form-body-left,
  .form-body-right {
    padding-top: 10px;
    width: 630px;
  }
  .form-body-right {
    padding-left: 85px;
    position: relative;
    .form-body-right-mask {
      z-index: 9999;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#ababab, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-size: 16px;
        color: #fff;
      }
    }
    .vip-consume {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .el-radio-group {
        .el-radio {
          margin: 0 5px !important;
        }
      }
      .consume-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        i {
          margin: 0 10px 10px 0;
        }
      }
    }
    .rfm-box {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      i {
        margin: 0 10px 10px 0 !important;
      }
    }
  }
}
.dialog-footer {
  .test-box {
    width: 520px;
    margin: auto;
    /deep/ .el-form-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .sub-box {
    display: flex;
    width: 520px;
    margin: auto;
    justify-content: center;
    align-items: center;
    .person-count {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.message {
  padding-right: 10px;
}
.message-container {
  margin: 10px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .message-left {
    width: 370px;
    .message-area {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    > div {
      margin-bottom: 10px;
    }
  }
  .message-right {
    width: 240px;
    .review-box {
      margin: 0 4px;
      background: #f3f3f3;
      height: 100%;
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .review-text {
        margin: 20px 0;
        text-align: center;
        color: #bebebe;
        font-size: 16px;
      }
      .review {
        width: 220px;
        .review-item {
          background-color: #fff;
          padding: 6px;
          .title {
            font-size: 14px;
            margin-bottom: 10px;
          }
          .desc {
            font-size: 12px;
            margin-bottom: 10px;
            line-height: 14px;
          }
          .item {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 10px;
            .name {
              font-size: 14px;
              color: #b4b4b4;
              width: 90px;
            }
            .value {
              width: 120px;
              font-size: 12px;
              margin-left: 20px;
              line-height: 14px;
              p {
                line-height: 16px;
              }
            }
          }
        }
        .review-bottom {
          padding: 10px;
          background-color: #fff;
          border-top: 1px solid #eee;
          p {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
    }
  }
  /deep/ .el-color-picker__trigger {
    width: 20px !important;
  }
}
.fixed-number-tips {
  color: #e6bb99;
}
.select-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
