<template>
  <el-dialog
    title="选择标题"
    :visible.sync="show"
    width="800px"
    top="5vh"
    append-to-body
    :before-close="handleClose"
  >
    <div class="service-components-selectTitle">
      <div class="flex-between">
        <el-select
          v-model="categoryId"
          placeholder="请先选择分类"
          style="width: 180px"
          filterable
          @change="handlePageChange(1)"
        >
          <el-option
            v-for="item in classifyList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <div>
          <el-input
            placeholder="请输入标题"
            v-model="title"
            class="input-with-select"
            @keydown.enter.native="handlePageChange(1)"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handlePageChange(1)"
            ></el-button>
          </el-input>
          <el-button @click="handleAdd" type="primary" :disabled="!categoryId"
            >添加标题</el-button
          >
        </div>
      </div>

      <el-table
        :data="titleList"
        max-height="580px"
        v-loading="loading"
        highlight-current-row
        @current-change="(row) => (this.currentTitle = row)"
        style="width: 100%; margin: 20px 0"
      >
        <el-table-column prop="select" label="选择" width="50">
          <template slot-scope="scope">
            <el-radio
              type="radio"
              :value="currentTitle ? currentTitle.id : null"
              :label="scope.row.id"
            ></el-radio>
          </template>
        </el-table-column>
        <el-table-column property="title" label="标题"> </el-table-column>
      </el-table>
      <el-pagination
        style="text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="15"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <div class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSure">确 定</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getTitleList, editTitle } from "../../../api/classify";

export default {
  name: "selectTitle",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    classifyList: Array,
  },
  data() {
    return {
      title: null,
      titleList: [],
      page: 1,
      total: 0,
      loading: false,
      currentTitle: {},
      categoryId: null,
    };
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          const data = this.classifyList[0];
          this.categoryId = data ? data.id : null;
          this.handlePageChange(1);
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleAdd() {
      this.$prompt("请输入标题", "新增", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "text",
      })
        .then(({ value }) => {
          if (value) {
            editTitle({
              title: value,
              categoryId: this.categoryId,
            }).then(() => {
              this.handlePageChange(1);
              this.$message.success("保存成功");
            });
          }
        })
        .catch(() => {});
    },
    handleSure() {
      this.$emit("success", this.currentTitle.title);
      this.handleClose();
    },
    handlePageChange(page) {
      this.page = page;
      this.getList();
    },
    getList() {
      if (this.categoryId) {
        this.loading = true;
        getTitleList({
          page: this.page,
          pageSize: 20,
          title: this.title,
          categoryId: this.categoryId,
        })
          .then((res) => {
            this.titleList = res.list;
            this.total = res.total;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    handleClose() {
      this.page = 1;
      this.title = "";
      this.$emit("close");
    },
  },
};
</script>

<style lang='scss' scoped>
.service-components-selectTitle {
  overflow-x: hidden;
}
/deep/ .el-pagination__jump {
  position: relative;
  top: -1px;
}
.input-with-select {
  width: 200px;
  margin-right: 20px;
}
/deep/ .el-drawer {
  padding: 20px;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
.dialog-footer {
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
}
</style>
