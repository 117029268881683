<template>
  <el-dialog
    :title="`${info ? '编辑' : '新增'}卡片`"
    :visible.sync="show"
    append-to-body
    center
    width="700px"
    :before-close="handleClose"
  >
    <el-row :gutter="20">
      <el-form label-position="left" label-width="120px" class="form-container">
        <!-- 表单顶部 -->
        <div class="form-top">
          <el-form-item label="消息内容">
            <div>
              <input
                type="file"
                ref="uploadFile"
                style="display: none"
                accept="image/*"
                @change="handleFileChange"
              />
              <el-form-item
                v-loading="miniprogramLoading"
                label="小程序封面"
                class="necessary"
              >
                <div class="selectMiniPicContainer">
                  <div class="changeMiniPicType">
                    <el-radio v-model="selectMiniPicType" :label="1"
                      >选择素材</el-radio
                    >
                    <el-radio
                      style="margin-left: 0"
                      v-model="selectMiniPicType"
                      :label="2"
                      >本地上传</el-radio
                    >
                  </div>
                  <div class="selectMiniPicBox">
                    <div
                      @click="handleChangeMiniPic"
                      v-if="!minForm.picurl"
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 30px;
                        width: 200px;
                        height: 100px;
                        margin-right: 10px;
                        border: 1px solid #dcdfe6;
                        border-radius: 4px;
                        cursor: pointer;
                      "
                    >
                      <div><i class="el-icon-plus"></i></div>
                    </div>
                    <img
                      @click="handleChangeMiniPic"
                      v-if="minForm.picurl"
                      :src="minForm.picurl"
                      style="
                        width: 200px;
                        height: 100px;
                        margin-right: 10px;
                        cursor: pointer;
                      "
                    />
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="标题" class="necessary">
                <el-input
                  type="text"
                  v-model="minForm.title"
                  style="width: 300px; margin-right: 10px"
                />
                <el-button
                  type="text"
                  style="padding: 0 !important"
                  @click="showTitleDialog = true"
                  >选择标题</el-button
                >
              </el-form-item>
              <el-form-item label="小程序" class="necessary">
                <el-select
                  v-model="minForm.appid"
                  style="width: 300px; margin-right: 10px"
                  placeholder="请选择小程序"
                  clearable
                >
                  <el-option
                    label="书币幸运抽"
                    value="wx5fadfde3504664de"
                  ></el-option>
                  <el-option
                    label="书币欢乐送"
                    value="wxb388db78e608d8ec"
                  ></el-option>
                  <el-option
                    label="书币幸运送"
                    value="wx48f4f9d8e44ee1f2"
                  ></el-option>
                  <el-option
                    label="欢乐送书币"
                    value="wx5095354e603e3427"
                  ></el-option>
                  <el-option
                    label="西柚热播剧"
                    value="wx359c5aae0372157f"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="页面地址" class="necessary">
                <el-input
                  type="text"
                  v-model="minForm.pagepath"
                  style="width: 300px; margin-right: 10px"
                />
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item label="测试人" style="margin-bottom: 14px">
            <el-select
              v-model="testId"
              filterable
              remote
              reserve-keyword
              placeholder="请输入用户名"
              :remote-method="userQuery"
              :loading="remoteLoading"
              @change="handleTestChange"
            >
              <el-option
                v-for="item in testUser"
                :key="item.openId"
                :label="item.nickname"
                :value="item.openId"
              >
                <div class="row">
                  <user-avatar :info="item" name-prop="nickname" :size="25" />
                  <span style="margin-left: 10px">{{ item.nickname }}</span>
                </div>
              </el-option>
            </el-select>
            <el-button
              style="margin-left: 10px"
              type="primary"
              :loading="loadingButton"
              @click="handleTest"
              >发送</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <div class="sub-box">
        <div class="button-box">
          <el-button @click="handleClose">取 消</el-button>
          <el-button
            style="margin-left: 10px"
            type="primary"
            @click="handleSubmit"
            :loading="submitLoading"
            >确定</el-button
          >
        </div>
      </div>
    </span>
    <!-- 选择图片 -->
    <select-image
      :show="showPicDialog"
      :classifyList="classifyList"
      @close="showPicDialog = false"
      @success="handleSelectImageSuccess"
    />
    <select-title
      :show="showTitleDialog"
      :classifyList="classifyList"
      :insertInfoType="2"
      @close="showTitleDialog = false"
      @success="handleInsertBookTitle"
    />
  </el-dialog>
</template>

<script>
import SelectImage from "../../service/components/selectPic";
import SelectTitle from "../../service/components/selectTitle";
import area from "@/assets/js/area.js";
import {
  platformLocalUpload,
  platformLocalSelectUpload,
  getAccountUser,
  testSend,
  changeAppletSendCardList,
  addAppletSendCardList,
} from "@/api/service";
import { getClassifyList } from "@/api/classify";
import UserAvatar from "@/components/UserAvatar";
import SendDetailTable from "../../service/components/sendDetailTable.vue";
import { messagePickerOptions } from "@/assets/js/options";

export default {
  name: "edit",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    account: {
      type: Array,
      default: () => [],
    },
    info: Object,
    accountId: Number,
    appletId: Number,
    appids: Array,
  },
  data() {
    return {
      testId: null,
      str: "",
      estimateNum: null,
      form: {},
      classifyList: [],
      showDescDialog: false,
      pickerOptions: messagePickerOptions,
      bookList: [],
      loading: false,
      formLoading: false,
      defaultBook: null,
      submitLoading: false,
      testUser: [],
      remoteLoading: false,
      // 新增客户信息相关
      area,
      areaCity: [],
      gridData: [
        {
          label: "重要价值客户",
          R: "高",
          F: "高",
          M: "高",
          scene: "最近充值-高频次-高金额",
          level: "A类",
        },
        {
          label: "重要深耕客户",
          R: "高",
          F: "低",
          M: "高",
          scene: "最近充值-低频次-高金额",
          level: "A类",
        },
        {
          label: "重要唤回客户",
          R: "低",
          F: "高",
          M: "高",
          scene: "最近未充-高频次-高金额",
          level: "B类",
        },
        {
          label: "重要挽留客户",
          R: "低",
          F: "低",
          M: "高",
          scene: "最近未充-低频次-高金额",
          level: "B类",
        },
        {
          label: "潜力客户",
          R: "高",
          F: "高",
          M: "低",
          scene: "最近充值-高频次-低金额",
          level: "B类",
        },
        {
          label: "一般发展客户",
          R: "高",
          F: "低",
          M: "低",
          scene: "最近充值-低频次-低金额",
          level: "B类",
        },
        {
          label: "一般维持客户",
          R: "低",
          F: "高",
          M: "低",
          scene: "最近未充-高频次-低金额",
          level: "B类",
        },
        {
          label: "流失客户",
          R: "低",
          F: "低",
          M: "低",
          scene: "最近未充-低频次-低金额",
          level: "C类",
        },
      ],
      // 获取人数参数
      showDetail: false,
      miniprogramLoading: false,
      // 小程序卡片选择改版
      isSelectMiniImage: false,
      selectMiniPicType: 1,
      minForm: {
        picurl: "",
        pagepath: "",
        thumb_media_id: "",
        appid: "",
        title: "",
      },
      showPicDialog: false,
      loadingButton: false,
      showTitleDialog: false,
    };
  },
  watch: {
    show(newVal) {
      if (newVal) {
        if (sessionStorage.getItem("testNickName")) {
          this.userQuery(sessionStorage.getItem("testNickName"));
          this.testId = sessionStorage.getItem("testId");
        } else {
          this.testId = null;
        }
        this.estimateNum = null;
        this.userQuery();
        if (this.info) {
          this.minForm = {
            appid: this.info.appId,
            appletId: this.appletId,
            picurl: this.info.cover,
            pagepath: this.info.page,
            title: this.info.title,
            thumb_media_id: this.info.thumbMediaId,
          };
        } else {
          this.minForm = {
            appletId: this.appletId,
            pagepath : "pages/index/index"
          };
        }
      }
    },
  },
  mounted() {
    getClassifyList({ type: 1, status: 1 }).then((res) => {
      this.classifyList = res;
    });
  },
  methods: {
    handleInsertBookTitle(title) {
      this.$set(this.minForm, "title", title);
    },
    // 缓存发送人
    handleTestChange(item) {
      let nickName = null;
      this.testUser.forEach((element) => {
        if (element.openId === item) {
          nickName = element.nickname;
        }
      });
      sessionStorage.setItem("testNickName", nickName);
      sessionStorage.setItem("testId", item);
    },
    handleTest() {
      const { msgType, remark } = this.form;
      const { testId } = this;
      if (!this.minForm.thumb_media_id) {
        return this.$message.error("请选择小程序封面");
      }
      if (!this.minForm.title) {
        return this.$message.error("请填写标题");
      }
      if (!this.minForm.appid) {
        return this.$message.error("请选择小程序");
      }
      if (!this.minForm.pagepath) {
        return this.$message.error("请填写pagepath");
      }
      if (!testId) {
        this.$message.error("请输入测试openid");
        return false;
      }
      const params = {
        accountChannelId: this.accountId,
        msgType: "miniprogrampage",
        openId: testId,
        remark,
      };
      params.content = JSON.stringify({
        title: this.minForm.title,
        appid: this.minForm.appid,
        // pagepath: "pages/index/index",
        pagepath: this.minForm.pagepath,
        thumb_media_id: this.minForm.thumb_media_id,
      });
      params.novelName = this.minForm.picurl;
      this.loadingButton = true;
      testSend(params)
        .then(() => {
          this.$message.success("发送成功");
        })
        .finally(() => {
          this.loadingButton = false;
        });
    },
    userQuery(name) {
      this.remoteLoading = true;
      getAccountUser(this.accountId, name)
        .then((res) => {
          this.testUser = res;
        })
        .finally(() => (this.remoteLoading = false));
    },
    handleClose() {
      this.$confirm("确定要取消操作吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$emit("close");
      });
    },
    handleSelectImageSuccess(data) {
      this.minForm.picurl = data;
      this.miniprogramLoading = true;
      platformLocalSelectUpload({
        accountChannelId: this.accountId,
        url: data,
        type: "thumb",
      })
        .then((res) => {
          this.minForm.thumb_media_id = res.media_id;
        })
        .finally(() => {
          this.miniprogramLoading = false;
          this.isSelectMiniImage = false;
        });
    },
    handleChangeMiniPic() {
      if (this.selectMiniPicType == 2) {
        this.$refs.uploadFile.click();
      } else {
        this.showPicDialog = true;
        this.isSelectMiniImage = true;
      }
    },
    handleFileChange(e) {
      const targetFile = e.target.files[0];
      const file = new FormData();
      file.append("file", targetFile);
      // return;
      this.miniprogramLoading = true;
      platformLocalUpload(this.accountId, "thumb", file)
        .then((res) => {
          this.minForm.picurl = res.url;
          this.minForm.thumb_media_id = res.media_id;
        })
        .finally(() => {
          this.miniprogramLoading = false;
        });
    },
    // 关闭详情
    closeDetail() {
      this.showDetail = false;
    },
    handleSubmit() {
      if (!this.minForm.picurl) {
        return this.$message.error("请选择小程序封面");
      }
      if (!this.minForm.title) {
        return this.$message.error("请填写标题");
      }
      if (!this.minForm.appid) {
        return this.$message.error("请选择小程序");
      }
      if (!this.minForm.pagepath) {
        return this.$message.error("请填写页面地址");
      }
      const params = {
        appletId: this.minForm.appletId,
        appId: this.minForm.appid,
        title: this.minForm.title,
        page: this.minForm.pagepath,
        cover: this.minForm.picurl,
        thumbMediaId: this.minForm.thumb_media_id,
      };
      if (!this.appids.includes(this.minForm.appid) && this.appids.length) {
        return this.$confirm("此小程序未在该公众号上绑定确定要添加?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.handleSendForm(params);
        });
      } else {
        this.handleSendForm(params);
      }
    },
    handleSendForm(params) {
      this.submitLoading = true;
      if (this.info) {
        changeAppletSendCardList(this.info.id, {
          ...params,
        })
          .then((res) => {
            this.$message.success("修改成功");
            this.$emit("close");
            this.$emit("refresh");
          })
          .finally(() => {
            this.submitLoading = false;
          });
      } else {
        addAppletSendCardList({
          ...params,
        })
          .then((res) => {
            this.$message.success("新增成功");
            this.$emit("close");
            this.$emit("refresh");
          })
          .finally(() => {
            this.submitLoading = false;
          });
      }
    },
    handleClose() {
      this.$emit("close");
    },
  },
  components: {
    SendDetailTable,
    SelectImage,
    UserAvatar,
    SelectTitle,
  },
};
</script>

<style lang='scss' scoped>
.form-container {
  width: 600px;
  margin: auto;
}
.form-container-left {
  width: 600px;
  margin-left: auto;
}
.form-container-right {
  width: 600px;
  margin-right: auto;
}
/deep/ .el-button {
  margin-right: 0px !important;
  margin-left: 0px;
}
/deep/ .el-drawer__body {
  overflow-y: auto;
}
/deep/ .el-drawer {
  padding: 20px;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
// /deep/ .el-input-number__decrease{
//   top:1px;
// }
a {
  -webkit-tap-highlight-color: transparent;
}

.example {
  width: 375px;
  background-color: #f1f1f1;
  padding: 10px;
}

.card {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
}

.mesg-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-weight: bold;
  line-height: 20px;
}

.detail {
  display: flex;
  align-items: center;
}

.detail p {
  flex: 1;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 15px;
}

.detail img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: #f5f7fa;
  color: #909399;
}
.message-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-mask {
  border: 1px solid #c0c4cc;
  border-radius: 4px;
  min-height: 259px;
}
.dialog-footer {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
/* .text-mask > span {
  padding-left: 10px;
  font-size: 12px;
} */

/**
**
**改版客服消息排版相关css
**
**/
// 新增属性
.inner-form {
  width: 480px;
  border: 1px solid #f6f6f6;
  border-radius: 20px;
  padding: 10px;
  background-color: #f6f6f6;
  margin-left: -10px;
}
.form-container /deep/ .el-form-item__label {
  font-size: 13px;
}
.form-container /deep/ .el-radio-button--medium .el-radio-button__inner {
  font-size: 13px;
}
.form-container .el-radio__label {
  font-size: 13px;
}
/deep/ .el-dialog__body {
  padding-bottom: 0 !important;
  padding-top: 10px !important;
}
.el-form-item--small.el-form-item {
  margin-bottom: 10px;
}
.form-top {
  background-color: #fbfbfb;
  padding: 10px 20px 20px 20px;
}
/deep/ .necessary > .el-form-item__label {
  position: relative;
}
/deep/ .necessary > .el-form-item__label::before {
  position: absolute;
  top: 0;
  left: -8px;
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
.form-body {
  background-color: #fbfbfb;
  margin-top: 10px;
  padding: 0 20px;
  padding-bottom: 0;
  display: flex;
  .message-box {
    justify-content: flex-start;
  }
  .form-body-left {
    border-right: 1px dashed #d9d9d9;
  }
  .form-body-left,
  .form-body-right {
    padding-top: 10px;
    width: 630px;
  }
  .form-body-right {
    padding-left: 85px;
    position: relative;
    .form-body-right-mask {
      z-index: 9999;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#ababab, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-size: 16px;
        color: #fff;
      }
    }
    .vip-consume {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .el-radio-group {
        .el-radio {
          margin: 0 5px !important;
        }
      }
      .consume-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        i {
          margin: 0 10px 10px 0;
        }
      }
    }
    .rfm-box {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      i {
        margin: 0 10px 10px 0 !important;
      }
    }
  }
}
.dialog-footer {
  .test-box {
    width: 520px;
    margin: auto;
    /deep/ .el-form-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .sub-box {
    display: flex;
    width: 520px;
    margin: auto;
    justify-content: center;
    align-items: center;
    .person-count {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.message {
  padding-right: 10px;
}
.message-container {
  margin: 10px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .message-left {
    width: 370px;
    .message-area {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    > div {
      margin-bottom: 10px;
    }
  }
  .message-right {
    width: 240px;
    .review-box {
      margin: 0 4px;
      background: #f3f3f3;
      height: 100%;
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .review-text {
        margin: 20px 0;
        text-align: center;
        color: #bebebe;
        font-size: 16px;
      }
      .review {
        width: 220px;
        .review-item {
          background-color: #fff;
          padding: 6px;
          .title {
            font-size: 14px;
            margin-bottom: 10px;
          }
          .desc {
            font-size: 12px;
            margin-bottom: 10px;
            line-height: 14px;
          }
          .item {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 10px;
            .name {
              font-size: 14px;
              color: #b4b4b4;
              width: 90px;
            }
            .value {
              width: 120px;
              font-size: 12px;
              margin-left: 20px;
              line-height: 14px;
              p {
                line-height: 16px;
              }
            }
          }
        }
        .review-bottom {
          padding: 10px;
          background-color: #fff;
          border-top: 1px solid #eee;
          p {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
    }
  }
  /deep/ .el-color-picker__trigger {
    width: 20px !important;
  }
}
.fixed-number-tips {
  color: #e6bb99;
}
.select-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
