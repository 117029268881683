<template>
  <el-dialog
    title="模板消息复制"
    :close-on-click-modal="false"
    :visible.sync="show"
    center
    width="500px"
    top="20px"
    append-to-body
    :before-close="handleClose"
  >
    <div class="copy-box">
      <el-form label-width="100px">
        <el-form-item label="复制到：">
          <el-select
            v-model="CopyOfficial"
            placeholder="请选择公众号"
            multiple
            style="width: 280px"
            filterable
          >
            <el-option
              v-for="item in currentPlatChannelList"
              :key="item.id"
              :label="item.channelName"
              :value="item.id"
            >
              <span style="float: left">{{ item.channelName }}</span>
              <span style="float: right; margin-left: 15px; font-size: 13px"
                >{{ item.platformStr }} - {{ item.uname }}</span
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="定时日期：">
          <el-date-picker
            style="width: 280px"
            v-model="form.tick"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="定时时间：">
          <el-time-picker
            style="width: 280px"
            v-model="form.tickTime"
            :picker-options="{
              selectableRange: '00:00:01 - 23:59:59',
            }"
            value-format="HH:mm:ss"
            type="time"
            placeholder="选择时间"
          >
          </el-time-picker>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleConfirm" :loading="loading"
        >确定</el-button
      >
      <el-button @click="handleClose">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import {
  messagePlatformList,
  disableIncludeTodayOptions,
} from "@/assets/js/options";
import { copyAppletSend } from "@/api/service";
export default {
  name: "edit",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    info: Object,
  },
  watch: {
    show: function (nv) {
      if (nv) {
        this.currentChannelId = this.info.accountChannelId;
      }
    },
  },
  data() {
    return {
      CopyOfficial: [],
      copyNum: null,
      form: {},
      pickerOptions: disableIncludeTodayOptions,
      currentPlatform: null,
      currentChannelId: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["officialList"]),
    currentPlatChannelList() {
      let tmpplat = this.officialList.find((item) => {
        return item.id === this.currentChannelId;
      });
      if (!tmpplat) {
        return [];
      }
      return this.officialList.filter(
        (item) =>
          messagePlatformList.includes(item.platform) &&
          item.status === 1 &&
          item.platform === tmpplat.platform
      );
    },
  },
  methods: {
    handleConfirm() {
      if (!this.CopyOfficial.length) {
        return this.$message.error("请选择公众号");
      }
      if (!this.form.tickTime) {
        return this.$message.error("请选择定时时间");
      }
      if (!this.form.tick) {
        return this.$message.error("请选择定时日期");
      }
      this.loading = true;
      copyAppletSend({
        id: this.info.id,
        accountChannelIds: this.CopyOfficial,
        ...this.form,
      })
        .then((res) => {
          this.$message.success("复制成功");
          this.$emit("close");
          this.$emit("refresh");
          this.form = {};
          this.CopyOfficial = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleClose() {
      this.form = {};
      this.CopyOfficial = [];
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__wrapper {
  overflow: hidden;
}
/deep/ .el-dialog__body {
  padding-bottom: 0;
}

.service-detail {
  box-sizing: border-box;
  padding: 20px;
  height: 780px;
  overflow: auto;
}
.dialog-footer {
  display: inline-block;
  width: 100%;
  // margin-top:20px;
  text-align: center;
}
.form-container {
  max-height: 800px;
  // overflow: auto;
  padding-right: 10px;
}
</style>
