<template>
  <el-dialog
    title="选择图片"
    :visible.sync="show"
    width="700px"
    top="5vh"
    append-to-body
    :before-close="handleClose"
  >
    <div>
      <div class="flex-between" style="margin-bottom: 20px">
        <el-select
          v-model="categoryId"
          placeholder="请先选择分类"
          style="width: 180px"
          filterable
          @change="handlePageChange(1)"
        >
          <el-option
            v-for="item in classifyList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-input
          v-model="title"
          placeholder="请输入图片标题"
          style="width: 260px; margin-left: 10px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getList"
          ></el-button>
        </el-input>
        <el-button
          @click="isUpload = true"
          type="primary"
          style="margin-right: 20px; margin-bottom: 0px"
          :disabled="!categoryId"
          >添加图片
        </el-button>
      </div>
      <template>
        <div class="image-cont" v-if="imgList.length" v-loading="loading">
          <div v-for="(item, index) in imgList" :key="index">
            <img
              :src="item.picurl"
              alt=""
              class="image"
              @click="handleSure(item.picurl)"
            />
          </div>
        </div>
        <p v-else class="empty">暂无可选择图片</p>
      </template>
      <el-pagination
        style="text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <div class="dialog-footer" style="margin-bottom: 40px">
        <el-button @click="handleClose">取 消</el-button>
      </div>
      <el-dialog
        :visible.sync="isUpload"
        append-to-body
        title="添加图片"
        width="500px"
      >
        <add-image
          :show="isUpload"
          :category-id="categoryId"
          @close="isUpload = false"
          @refresh="handlePageChange(1)"
        />
      </el-dialog>
    </div>
  </el-dialog>
</template>

<script>
import { getImageList } from "../../../api/classify";
import AddImage from "../../classify/customer/image/addImage";

export default {
  name: "selectTitle",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    classifyList: Array,
  },
  data() {
    return {
      imgList: [],
      page: 1,
      total: 0,
      loading: false,
      isUpload: false,
      categoryId: null,
      title: "", // 图片标题
      pageSize: 12,
    };
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          const data = this.classifyList[0];
          this.categoryId = data ? data.id : null;
          this.handlePageChange(1);
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleSure(url) {
      this.handleClose();
      this.$emit("success", url);
    },
    handlePageChange(page) {
      this.page = page;
      this.getList();
    },
    getList() {
      if (this.categoryId) {
        this.loading = true;
        getImageList({
          page: this.page,
          pageSize: this.pageSize,
          categoryId: this.categoryId,
          title: this.title,
        })
          .then((res) => {
            this.imgList = res.list;
            this.total = res.total;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    handleClose() {
      this.page = 1;
      this.$emit("close");
    },
  },
  components: {
    AddImage,
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-drawer {
  padding: 20px;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
.image-cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  > div {
    position: relative;
    margin-bottom: 15px;
    margin-right: 10px;
    .image-size {
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
      color: #f5f5f5;
    }
  }
}

.image {
  width: 200px;
  height: 120px;
}

.empty {
  text-align: center;
  font-size: 14px;
  color: #999;
  margin: 20px auto;
}
.dialog-footer {
  margin-top: 40px;
  text-align: center;
}
</style>
