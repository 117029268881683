<template>
  <el-drawer
    :title="'卡片列表：' + info.remark"
    :visible.sync="show"
    center
    size="90vw"
    append-to-body
    :before-close="handleClose"
  >
    <div class="card-box">
      <dy-table
        :dataList="tableData"
        :rowList="rowList"
        :loading="loading"
        height="650px"
        @pageChange="handlePageChange"
      >
        <template #filter>
          <div class="search-box">
            <el-button class="margin-left-ten" @click="handlePageChange"
              >刷新数据</el-button
            >
            <div class="search-right">
              <el-button
                type="primary"
                @click="handleAddCard"
                icon="el-icon-plus"
                >卡片</el-button
              >
            </div>
          </div>
        </template>
        <template #cover="{ row }">
          <img style="width: 100px; height: 50px" :src="row.cover" alt="" />
        </template>
        <template #action="{ row }">
          <div>
            <!-- <el-tooltip
              class="item"
              effect="dark"
              content="详情"
              placement="top-start"
            >
              <el-button
                type="info"
                icon="el-icon-document"
                circle
                @click="handleDetail(row)"
              >
              </el-button>
            </el-tooltip> -->
            <el-button
              type="success"
              icon="el-icon-edit"
              circle
              @click="handleEdit(row)"
            >
            </el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="handleDelete(row)"
            >
            </el-button>
          </div>
        </template>
      </dy-table>
    </div>
    <!-- 新增编辑 -->
    <editCard
      :appids="appids"
      :show="showAddCard"
      :account="account"
      :info="currentCard"
      :appletId="info.id"
      :accountId="accountId"
      @close="showAddCard = false"
      @refresh="handlePageChange(1)"
    />
    <!-- 模板列表 -->

    <!-- 详情 -->
  </el-drawer>
</template>

<script>
import {
  getAppletSendCardList,
  deleteAppletSendCardList,
  copyTemplateTag,
} from "@/api/service";
import editCard from "./editCard";
export default {
  name: "edit",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    info: Object,
    account: Array,
    accountId: Number,
  },
  components: {
    editCard,
  },
  data() {
    return {
      CopyOfficial: [],
      copyNum: null,
      form: {},
      tableData: [],
      rowList: [
        {
          prop: "id",
          label: "id",
        },
        {
          prop: "title",
          label: "标题",
          colWidth: "300",
          popover: true,
        },
        {
          prop: "cover",
          label: "封面",
          slot: true,
        },
        {
          prop: "page",
          label: "页面地址",
        },
        {
          prop: "appId",
          label: "appId",
        },
        {
          prop: "action",
          label: "操作",
          slot: true,
        },
      ],
      loading: false,
      showAddCard: false,
      currentCard: null,
      showTemplate: false,
      showDetail: false,
      appids: [],
    };
  },
  watch: {
    show: function (nv) {
      if (nv) {
        this.handlePageChange();
      }
    },
  },
  computed: {},
  methods: {
    handleShowTemplate(row) {
      this.currentCard = row;
      this.showTemplate = true;
    },
    handleDelete(row) {
      this.$confirm("确定要删除此条标签?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteAppletSendCardList(row.id).then((res) => {
          this.$message.success("删除成功");
          this.handlePageChange(1);
        });
      });
    },
    handleDetail(row) {
      this.currentCard = row;
      this.showDetail = true;
    },
    handleEdit(row) {
      this.currentCard = row;
      this.showAddCard = true;
    },
    handleAddCard() {
      this.currentCard = null;
      this.showAddCard = true;
    },
    handlePageChange() {
      this.loading = true;
      getAppletSendCardList({
        appletId: this.info.id,
      })
        .then((res) => {
          this.tableData = res;
          this.appids = res.map((item) => {
            return item.appId;
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleClose() {
      this.$emit("close");
    },
  },
  created() {
    this.handlePageChange();
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__wrapper {
  overflow: hidden;
}
/deep/ .el-dialog__body {
  padding-bottom: 0;
}

.service-detail {
  box-sizing: border-box;
  padding: 20px;
  height: 780px;
  overflow: auto;
}
.dialog-footer {
  display: inline-block;
  width: 100%;
  // margin-top:20px;
  text-align: center;
}
.form-container {
  max-height: 800px;
  // overflow: auto;
  padding-right: 10px;
}
.search-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .search-right {
    margin-right: 10px;
  }
}
</style>
