<template>
  <div>
    <div
      class="
        part
        border-radius-6
        box-shadow-light-grey
        padding-20
        height-calc-type1
      "
    >
      <div class="flex-between operation padding-bottom-20">
        <div>
          <el-button
            @click="handlePageChange(1)"
            class="margin-right-twentyFour"
            >刷新数据</el-button
          >
          <el-select
            class="margin-right-twentyFour"
            v-model="params.status"
            placeholder="状态"
            style="width: 140px"
            clearable
            @change="handlePageChange(1)"
          >
            <el-option label="未开始" :value="1"></el-option>
            <el-option label="进行中" :value="2"></el-option>
            <el-option label="结束" :value="3"></el-option>
          </el-select>
          <el-input
            class="margin-right-twentyFour"
            v-model="params.remark"
            style="width: 200px"
            placeholder="备注"
            clearable
            @clear="handleClear"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handlePageChange(1)"
            ></el-button
          ></el-input>
        </div>
        <el-button
          type="primary"
          @click="handleDialogShow(null)"
          icon="el-icon-plus"
          >新增群发</el-button
        >
      </div>
      <el-table
        class="table-service"
        v-loading="loading"
        :data="tableData"
        height="calc(100vh - 320px)"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="id" width="55" label="ID"></el-table-column>
        <el-table-column prop="channelName" label="公众号"></el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="{ row }">
            <el-tag v-if="row.status === 1" type="warning">未开始</el-tag>
            <el-tag v-else-if="row.status === 2">进行中</el-tag>
            <el-tag v-else-if="row.status === 3" type="success">结束</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="110" prop="tick" label="定时日期">
        </el-table-column>
        <el-table-column width="110" prop="tickTime" label="定时时间">
        </el-table-column>
        <el-table-column prop="intervalTime" label="间隔天数">
        </el-table-column>
        <el-table-column width="110" prop="nextStartDate" label="下次发送日期">
        </el-table-column>
        <el-table-column width="200" prop="createdTime" label="创建时间">
        </el-table-column>
        <el-table-column width="180" prop="remark" label="备注">
        </el-table-column>
        <el-table-column
          prop="action"
          width="240"
          fixed="right"
          class="test"
          label="操作"
        >
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="卡片"
              placement="top-start"
            >
              <el-button
                type="warning"
                icon="el-icon-postcard"
                circle
                @click="handleShowCard(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="编辑"
              placement="top-start"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                @click="handleDialogShow(scope.row, 'isEdit')"
              ></el-button>
            </el-tooltip>
            <el-dropdown
              size="medium"
              style="vertical-align: middle; margin-left: 10px"
              trigger="click"
              @command="(command) => handleRowClick(command, scope.row)"
            >
              <el-button class="button-small">
                更多<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="copy">复制消息</el-dropdown-item>
                <el-dropdown-item v-if="scope.row.status == 2" command="stop"
                  >停止发送</el-dropdown-item
                >
                <el-dropdown-item style="color: #f56c6c" command="delete"
                  >删除</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 新增编辑 -->
    <editApplet
      :show="showAdd"
      :account-id="accountChannelId"
      :account="channelList"
      :info="currentItem"
      @close="showAdd = false"
      @refresh="handlePageChange(1)"
    />
    <!-- 复制 -->
    <copyApplet
      :show="isCopy"
      :accountList="channelList"
      :info="currentItem"
      @close="isCopy = false"
      @refresh="handlePageChange(1)"
    />
    <!-- 卡片 -->
    <appletCard
      v-if="showCardList"
      :account-id="accountChannelId"
      :show="showCardList"
      :account="channelList"
      :info="currentItem"
      @close="showCardList = false"
      @refresh="handlePageChange(1)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getAppletSendList,
  deleteAppletSend,
  stopAppletSend,
} from "@/api/service";
import editApplet from "./editApplet.vue";
import copyApplet from "./copyApplet.vue";
import appletCard from "./appletCard.vue";
import { messagePlatformList } from "@/assets/js/options";
export default {
  name: "Applet",
  components: {
    editApplet,
    copyApplet,
    appletCard,
  },
  props: ["accountChannelId", "relationId"],
  data() {
    return {
      loading: false,
      status: null,
      page: 1,
      total: 0,
      pageSize: 20,
      tableData: [],
      isEdit: false,
      selected: [],
      params: {},
      // 新增编辑
      currentItem: null,
      showAdd: false,
      isCopy: false,
      showCardList: false,
    };
  },
  computed: {
    channelList() {
      const { officialList } = this;
      return officialList.filter(
        (item) =>
          messagePlatformList.includes(item.platform) && item.status === 1
      );
    },
    ...mapGetters(["officialList"]),
  },
  watch: {
    accountChannelId(newVal) {
      if (newVal) {
        this.handlePageChange(1);
      }
    },
    immediate: true,
  },
  methods: {
    handleSelectionChange() {},
    handlePageChange(page = this.page) {
      this.page = page;
      getAppletSendList({
        page: this.page,
        pageSize: this.pageSize,
        accountChannelId: this.accountChannelId,
        ...this.params,
      })
        .then((res) => {
          this.tableData = res.list;
          this.total = res.total;
        })
        .finally(() => {});
    },
    handleRowClick(type, data) {
      this.currentItem = data;
      if (type == "delete") {
        this.$confirm("确定要此条数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          deleteAppletSend(data.id).then((res) => {
            this.$message.success("删除成功");
            this.handlePageChange(1);
          });
        });
      } else if (type == "copy") {
        this.isCopy = true;
      } else if (type == "stop") {
        stopAppletSend(data.id)
          .then((res) => {
            this.$message.success("停止成功");
            this.handlePageChange(1);
          })
          .finally(() => {});
      }
    },
    handleDialogShow(data, type) {
      if (!data) {
        this.currentItem = null;
      } else {
        this.currentItem = data;
      }
      this.showAdd = true;
    },
    handleShowCard(data) {
      this.currentItem = data;
      this.showCardList = true;
    },
    handleClear() {},
  },
  created() {
    this.handlePageChange();
  },
};
</script>

<style lang="scss" scoped>
.operation {
  background: #fff;
  // padding: 0 15px;
  // box-shadow: -2px -2px 10px rgba(180, 180, 180, 0.1);
}
.part {
  box-sizing: border-box;
  background: #fff;
  // box-shadow: -2px -2px 10px rgba(180, 180, 180, 0.1);
}
/deep/ .el-drawer {
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
.table-service /deep/ .is-leaf {
  border-bottom: 0 !important;
  padding: 14px 0 !important;
}
</style>
